input[disabled],
textarea[disabled],
select[disabled='disabled'] {
    -webkit-text-fill-color: rgb(255, 255, 255);
    -webkit-opacity: 1;
    color:rgb(255, 255, 255);
    background: rgb(107, 114, 128)
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

body {
    background-color: #374151;
}